import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

export const AuthApi = {
  getDeviceCode: async () =>
    await api
      .post(`${endpoint}/device/init`)
      .then((result) => result.data?.data),
  login: async (params) =>
    await api
      .post(`${endpoint}/auth/login`, params)
      .then((result) => result.data),
  register: async (params) =>
    await api
      .post(`${endpoint}/auth/register`, params)
      .then((result) => result.data),
  logout: async () => await api.post(`${endpoint}/auth/logout`),
  getProfile: async () =>
    await api.get(`${endpoint}/auth/profile`).then((result) => result.data),
  getPermissions: async () =>
    await api
      .get(`${endpoint}/auth/get-permissions`)
      .then((result) => result.data),
  getEpic: async (params) =>
    await api
      .get(`${endpoint}/auth/get-epics`, params)
      .then((result) => result.data),
  getModule: async (params) =>
    await api
      .get(`${endpoint}/auth/get-modules`, params)
      .then((result) => result.data),
};
