// import { GoogleOAuthProvider } from '@react-oauth/google';
import Content from './Content';

const index = () => {
  return (
    // <GoogleOAuthProvider
    //   clientId={
    //     '814858878064-d9e2kj3qknr82ujli899pkpvf43jbjat.apps.googleusercontent.com'
    //   }
    // >
    <Content />
    // </GoogleOAuthProvider>
  );
};

export default index;
