import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router-dom';
import { IMiddleRouterProps } from '../_common/interface/router';
import { getQueryParams, updateQueryParams } from 'tera-dls';
import { CryptoJSAesEncrypt } from '_common/utils/hashHelper';

const MiddlewareRouter = observer(({ children }: IMiddleRouterProps) => {
  const { authStore } = useStores();
  const location = useLocation();

  const params: { [key: string]: any } = getQueryParams(location.search);

  if (authStore.device && !authStore.authenticated) {
    return (
      <Navigate
        to={`/auth/login${location.search}`}
        state={{ from: location }}
        replace
      />
    );
  }
  if (authStore.authenticated) {
    const bodyParams = {
      access_id: authStore?.access_id,
    };
    const queryParams = updateQueryParams({
      client_id: 'tera',
      req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
    });

    if (params?.callback) {
      window.open(`${params?.callback}${queryParams}`, '_self');
    } else {
      window.open(
        `${authStore?.redirect_url}/auth/check-auth${queryParams}`,
        '_self',
      );
    }
  }
  return <>{children}</>;
});

export default MiddlewareRouter;
