import { Route, Routes } from 'react-router-dom';

import MiddlewareRouter from 'routers/MiddlewareRouter';

import BasicLayout from '_common/component/Layout/BasicLayout';
import UnAuthLayout from '_common/component/Layout/UnAuthLayout';
import PageNotfound from '_common/component/PageNotfound';

// auth
import Activation from 'pages/Auth/Activation';
import Dashboard from 'pages/Dashboard';
import EnableBusiness from 'pages/Enable';
import BusinessUser from 'pages/Enable/BusinessUser';
import OtpFail from 'pages/Fail/OtpFail';
import RegistrationFail from 'pages/Fail/RegistrationFail';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ForgotPassword/ResetPassword';
import Login from 'pages/Login';
import OTP from 'pages/OTP';
import Register from 'pages/Register';
import RegistrationBusinessSuccess from 'pages/Success/RegistrationBusinessSuccess';
import SendForgotPasswordSuccess from 'pages/Success/SendForgotPasswordSuccess';
import WelcomeRegistration from 'pages/Success/WelcomeRegistration';

// dashboard router

export const Routers = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Dashboard />} />
      </Route>
      <Route path="auth" element={<UnAuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="register-user/success" element={<WelcomeRegistration />} />
        <Route
          path="register-business/success"
          element={<RegistrationBusinessSuccess />}
        />
        <Route
          path="forgot-password/success"
          element={<SendForgotPasswordSuccess />}
        />
        <Route path="otp/:id" element={<OTP />} />
        <Route path="business/enable" element={<EnableBusiness />} />
        <Route path="business-user/enable" element={<BusinessUser />} />
        <Route path="business/enable/fail" element={<RegistrationFail />} />
        <Route path="opt/fail" element={<OtpFail />} />
        {/* <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="otp/:id" element={<OTP />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} /> */}
        <Route path="account-activation" element={<Activation />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
};
