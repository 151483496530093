import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

export const AuthApi = {
  getDeviceCode: async () =>
    await api
      .post(`${endpoint}/device/init`)
      .then((result) => result.data?.data),
  login: async (params: any) =>
    await api
      .post(`${endpoint}/auth/login`, params)
      .then((result) => result.data),
  checkLogin: async (params) =>
    await api
      .post(`${endpoint}/auth/check-login`, params)
      .then((result) => result.data),
  register: async (params) =>
    await api
      .post(`${endpoint}/auth/register`, params)
      .then((result) => result.data),
  logout: async () => await api.post(`${endpoint}/auth/logout`),
  getProfile: async () =>
    await api.get(`${endpoint}/auth/profile`).then((result) => result.data),
  verifyOtp: async (params: any) =>
    await api
      .post(`${endpoint}/auth/verify-otp`, params)
      .then((result) => result.data),
  verifyToken: async (params: any) =>
    await api
      .post(`${endpoint}/auth/verify-token`, params)
      .then((result) => result.data),
  resendOtp: async (params: any) =>
    await api
      .post(`${endpoint}/auth/resend-otp`, params)
      .then((result) => result.data),
  activation: async (params: any) =>
    await api
      .post(`${endpoint}/auth/account-activation`, params)
      .then((result) => result.data),
  forgotPassword: async (params: any) =>
    await api
      .post(`${endpoint}/auth/forgot-password`, params)
      .then((result) => result.data),
  resetPassword: async (params: any) =>
    await api
      .post(`${endpoint}/auth/reset-password`, params)
      .then((result) => result.data),
};
