import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import { AdministratorApi } from '../../_api';
import { TObjectType, TParamsApiDof } from '../../interfaces';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import SelectEntity from '../SelectEntity';

export interface SelectDataTypeProps extends SelectProps {
  objectType?: TObjectType;
  paramsApi?: TParamsApiDof;
  idPrevent?: number;
  optionCustom?: OptionProps[];
  isCheckAll?: boolean;
}

const SelectDataType = forwardRef<HTMLSelectElement, SelectDataTypeProps>(
  (
    {
      objectType,
      isCheckAll = false,
      optionCustom = [],
      idPrevent,
      paramsApi,
      ...props
    },
    ref,
  ) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);
    const { form } = useTeraForm();
    const { item } = useTeraFormItem();
    const includesKey = form?.watch(`${item?.name}`);

    const { data } = useQuery(
      [
        'get-list-data-type',
        searchDebounce,
        paramsApi,
        objectType,
        includesKey,
      ],
      () =>
        AdministratorApi.getListDataType({
          keyword: searchDebounce,
          ...paramsApi,
          object_type: objectType,
          include_key: includesKey,
        }),
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionColumnConfig: OptionProps[] = useMemo(() => {
      const options = data?.data
        ?.filter((item) => item?.id !== idPrevent)
        .map((column) => ({
          label: column?.title || column?.concatenated_key,
          value: column?.concatenated_key,
        }));
      return options || [];
    }, [data, idPrevent]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch={data?.total_items > 15}
        labelInValue
        options={[...optionCustom, ...optionColumnConfig]}
        searchValue={search}
        onSearch={setSearch}
        isCheckAll={isCheckAll}
        placeholder="Vui lòng chọn"
        {...props}
      />
    );
  },
);

export default SelectDataType;
