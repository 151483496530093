import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

function BasicLayout() {
  return (
    <>
      <div id="body-content">
        <Outlet />
      </div>
    </>
  );
}

export default observer(BasicLayout);
