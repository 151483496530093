import { createContext } from 'react';
import { AuthStore } from './authStore';
import { IAuthStore } from './interface';
import { CommonStore } from './commonStore';

export class RootStore {
  authStore: IAuthStore;
  commonStore: CommonStore;
  constructor() {
    this.authStore = new AuthStore();
    this.commonStore = new CommonStore();
  }
}
export const rootStore = new RootStore();
export const rootStoreContext = createContext(rootStore);
